<template>
	<div
		class="d-flex align-center justify-center py-9 white"
		style="height: 80vh">
		<div
			class="d-flex align-center justify-center"
			style="height: 80vh"
			v-if="loadingPage">
			<v-progress-circular indeterminate> </v-progress-circular>
		</div>
		<v-card
			v-else-if="!centerSelected"
			rounded="lg"
			elevation="lg"
			class="ma-auto my-10 pa-9"
			width="500">
			<div class="text-center">
				<div class="text-h5 font-weight-bold mb-5">اختر الفرع</div>
				<v-select
					:disabled="loadingPage"
					:loading="loadingPage"
					:items="centers"
					v-model="selectedCenterId"
					item-text="Name"
					item-value="Id"
					label="اختر الفرع"
					class="mb-5"></v-select>
				<v-btn
					:disabled="selectedCenterId === null"
					@click="centerId = selectedCenterId"
					color="primary">
					اختيار
				</v-btn>
			</div>
		</v-card>
		<v-card
			v-else
			rounded="lg"
			elevation="lg"
			class="ma-auto my-10 pa-9"
			width="500">
			<div class="text-center">
				<div class="text-h5 font-weight-bold mb-5">تسجيل الدخول</div>
				<v-form
					ref="form"
					@submit.prevent="login">
					<v-text-field
						v-model="userName"
						name="name"
						label="اسم المستخدم"
						:rules="rules.required"
						id="name"></v-text-field>
					<v-text-field
						v-model="password"
						name="password"
						label="كلمة المرور"
						type="password"
						:rules="rules.required"
						id="password"
						class="mb-6"></v-text-field>
					<v-btn
						:loading="loading"
						:disabled="loading"
						type="submit"
						color="primary"
						class="mb-3">
						<span>دخول</span>
					</v-btn>
					<v-btn
						v-if="centers.length > 1"
						text
						small
						@click="
							centerId = null;
							selectedCenterId = null;
						"
						class="d-block mx-auto">
						تغير المركز
					</v-btn>
				</v-form>
			</div>
		</v-card>
	</div>
</template>

<script>
import rules from '@/helpers/validation rules';
import { mapState } from 'vuex';
import { MD5 } from 'crypto-js';

export default {
	name: 'Login',
	data() {
		return {
			userName: null,
			password: null,
			centerId: null,
			selectedCenterId: null,

			loading: false,
			loadingPage: true,
			rules,
		};
	},

	computed: {
		centerSelected() {
			return typeof this.centerId === 'number';
		},
		...mapState({
			centers: (state) => state.auth.centers,
		}),
	},

	methods: {
		MD5,
		login() {
			if (!this.$refs.form.validate()) {
				this.$eventBus.$emit(
					'show-snackbar',
					true,
					'يجب إدخال كلمة اسم المستخدم وكلمة السر',
				);
				return;
			}

			this.loading = true;
			this.$store
				.dispatch('auth/login', {
					userName: this.userName,
					password: this.MD5(this.password).toString(),
					centerId: this.centerId,
				})
				.then(() => {
					this.$router.push({ name: 'home' });
					this.$eventBus.$emit('show-snackbar', false, 'اهلا وسهلا');
					this.$eventBus.$emit('loader', false);
				})
				.catch((error) => {
					if (error.response?.code === 401) {
						this.$eventBus.$emit(
							'show-snackbar',
							true,
							'اسم السمتخدم او كلمة السر غير صحيحة',
						);
					} else {
						this.$eventBus.$emit(
							'show-snackbar',
							true,
							'حدث خطأ ما',
						);
					}
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},

	created() {
		this.loadingPage = true;
		this.$store.dispatch('auth/fetchCenters').then((res) => {
			if (this.centers.length === 1) this.centerId = res[0].Id;
			this.loadingPage = false;
		});
	},
};
</script>
