<template>
	<div
		class="d-flex align-center justify-center"
		style="height: 80vh"
		v-if="loading">
		<v-progress-circular indeterminate> </v-progress-circular>
	</div>

	<div v-else>
		<v-row>
			<!-- profile -->
			<v-col
				cols="12"
				lg="3">
				<div class="text-center">
					<div
						class="d-block d-sm-flex d-lg-block justify-space-around align-center">
						<!-- search -->
						<v-btn
							class="mb-sm-2 mb-0"
							text
							@click="dialog = true">
							<v-icon left>mdi-magnify</v-icon>
							بحث عن طالب
						</v-btn>
						<!-- name -->
						<h4 class="text-h5 font-weight-bold mb-2">
							{{ studentProfile.FullName }}
						</h4>

						<!-- specialty and course -->
						<h5 class="text-body-2 text--secondary mb-5">
							الصف:
							{{ studentProfile.SpecialtyName }}
							<br />
							الشعبة :
							{{ studentProfile.CourseName }}
						</h5>
					</div>

					<v-row>
						<!-- attendance -->
						<v-col
							cols="4"
							lg="12">
							<div class="d-flex flex-column align-center">
								<div class="text-caption">الدوام المحقق</div>
								<div>
									<span
										class="text-h5 font-weight-bold primary--text">
										{{
											studentProfile.Attendance
												.StudentRate
										}}
									</span>
									\
									{{ studentProfile.Attendance.TotalRate }}
								</div>
								<div
									class="text-caption font-weight-bold"
									v-if="
										studentProfile.Attendance.TotalRate -
										studentProfile.Attendance.StudentRate
									">
									{{
										studentProfile.Attendance.TotalRate -
										studentProfile.Attendance.StudentRate
									}}
									غيابات
								</div>
							</div>
						</v-col>

						<!-- rank -->
						<v-col
							cols="4"
							lg="12">
							<div
								class="d-flex flex-column align-center"
								v-if="studentProfile.StudentRank?.Rank">
								<div class="text-caption">
									ترتيب الطالب على الشعبة
								</div>
								<div
									class="text-h5 font-weight-bold primary--text">
									{{ studentProfile.StudentRank?.Rank }}
								</div>
							</div>
						</v-col>

						<!-- average -->
						<v-col
							cols="4"
							lg="12">
							<div class="d-flex flex-column align-center">
								<div class="text-caption">المعدل</div>
								<div
									v-if="
										!studentProfile.Average ||
										studentProfile.Average === '-'
									"
									class="text-h5 font-weight-bold primary--text">
									-
								</div>
								<template
									v-else-if="
										studentProfile.Average.split('/')
											.length > 1
									">
									<div
										class="text-h5 font-weight-bold primary--text">
										{{
											studentProfile.Average.split('/')[0]
										}}
									</div>
									<div class="text-caption font-weight-bold">
										\
										{{
											studentProfile.Average.split('/')[1]
										}}
									</div>
								</template>
								<template v-else>
									<div
										class="text-h5 font-weight-bold primary--text">
										{{ studentProfile.Average }}
									</div>
								</template>
							</div>
						</v-col>
					</v-row>
				</div>
			</v-col>

			<v-divider
				vertical
				v-if="!$vuetify.breakpoint.mdAndDown"></v-divider>

			<!-- main -->
			<v-col
				cols="12"
				lg="9">
				<!-- tabs -->
				<div class="d-flex flex-wrap justify-start align-center">
					<!-- profile -->
					<div
						@click="tab = 'profile'"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'profile' }">
						معلومات الطالب
					</div>
					<!-- tests -->
					<div
						@click="
							tab = 'tests';
							fetchTests();
							fetchSubjects();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'tests' }">
						الاختبارات
					</div>
					<!-- notes -->
					<div
						@click="
							tab = 'behavior';
							fetchNotes();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'behavior' }">
						ملاحظات سلوكية
					</div>
					<!-- manager notes -->
					<div
						@click="
							tab = 'notes';
							fetchManagerNotes();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'notes' }">
						ملاحظات إدارية
					</div>
					<!-- attendance -->
					<div
						@click="
							tab = 'attendance';
							fetchAttendance();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'attendance' }">
						سجل الحضور
					</div>
					<!-- funds -->
					<div
						@click="
							tab = 'finance';
							fetchFunds();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'finance' }">
						سجل مالي
					</div>
					<!-- working hour -->
					<div
						@click="
							tab = 'workingHours';
							fetchSchedule();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'workingHours' }">
						برنامج الدوام
					</div>
					<!-- chat -->
					<div
						@click="
							tab = 'massages';
							fetchMessages();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'massages' }">
						الرسائل
					</div>
					<!-- subjects -->
					<div
						@click="
							tab = 'subjects';
							fetchStaticsAndSubjects();
						"
						class="mobile-tab font-weight-bold py-1"
						:class="{ active: tab === 'subjects' }">
						المواد
					</div>
				</div>
				<v-chip-group
					v-if="false"
					class="mobile-chips"
					active-class="chipsColorActive"
					v-model="tab"
					column
					mandatory>
					<!-- profile -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="profile"
							@click="tab = 'profile'"
							:class="{ active: tab === 'profile' }">
							معلومات الطالب
						</v-chip>
					</div>
					<!-- tests -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="tests"
							@click="fetchTests()"
							:class="{ active: tab === 'tests' }">
							الاختبارات
						</v-chip>
					</div>
					<!-- notes -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="behavior"
							@click="fetchNotes()"
							:class="{ active: tab === 'behavior' }">
							ملاحظات سلوكية
						</v-chip>
					</div>
					<!-- manager notes -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="notes"
							@click="fetchManagerNotes()"
							:class="{ active: tab === 'notes' }">
							ملاحظات إدارية
						</v-chip>
					</div>
					<!-- attendance -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="attendance"
							@click="fetchAttendance()"
							:class="{ active: tab === 'attendance' }">
							سجل الحضور
						</v-chip>
					</div>
					<!-- funds -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="finance"
							@click="fetchFunds()"
							:class="{ active: tab === 'finance' }">
							سجل مالي
						</v-chip>
					</div>
					<!-- working hour -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="workingHours"
							@click="fetchSchedule()"
							:class="{ active: tab === 'workingHours' }">
							برنامج الدوام
						</v-chip>
					</div>
					<!-- chat -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="massages"
							@click="fetchMessages()"
							:class="{ active: tab === 'massages' }">
							الرسائل
						</v-chip>
					</div>
					<!-- subjects -->
					<div class="mobile-chips__item">
						<v-chip
							class="chipsColor"
							value="subjects"
							@click="fetchStaticsAndSubjects()"
							:class="{ active: tab === 'subjects' }">
							المواد
						</v-chip>
					</div>
				</v-chip-group>
				<v-divider></v-divider>
				<v-tabs-items
					v-model="tab"
					class="transparent"
					touchless>
					<!-- student profile -->
					<v-tab-item
						value="profile"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6 pa-4">
							<div
								:style="`column-count: ${
									$vuetify.breakpoint.mobile ? 1 : 2
								}`">
								<div>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											العنوان
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.Address
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											تاريخ الميلاد
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													moment(
														studentProfile.BirthDate,
													).format('D/M/YYYY')
														| isAvailable(
															!!studentProfile.BirthDate,
														)
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم الهاتف
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.Phone
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم ولي الامر المعتمد
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.ParentPhone
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											رقم الباص
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.BusNumber
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											اسم الاب
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.FatherName
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											مهنة الاب
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.FatherWork
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											اسم الام
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.MotherName
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
									<v-row
										no-gutters
										class="mb-2">
										<v-col
											class="text-body-2 pl-2"
											cols="6">
											مهنة الام
										</v-col>
										<v-col
											class="text-body-2 font-weight-bold pl-2"
											cols="6">
											<template>
												{{
													studentProfile.MotherWork
														| isAvailable
												}}
											</template>
										</v-col>
									</v-row>
								</div>
							</div>
						</v-card>
					</v-tab-item>

					<!-- student test -->
					<v-tab-item
						value="tests"
						class="pb-1">
						<!-- filter form -->
						<v-form class="mt-2">
							<v-row
								dense
								class="ma-0"
								align="center">
								<!-- test subject -->
								<v-col
									cols="12"
									md="5">
									<v-autocomplete
										v-model="testSubjectId"
										:items="subjectsItems"
										item-text="Name"
										item-value="Id"
										:loading="loadingTestsSubjects"
										:disabled="loadingTestsSubjects"
										label="المادة"
										outlined
										dense
										hide-details
										clearable
										:menu-props="{ offsetY: true }"
										class="rounded-lg"></v-autocomplete>
								</v-col>
								<!-- test detail -->
								<v-col
									cols="12"
									md="5">
									<v-text-field
										v-model="testDetails"
										:loading="loadingTestsSubjects"
										:disabled="loadingTestsSubjects"
										label="محتوى الاختبار"
										outlined
										dense
										hide-details
										append-icon="mdi-magnify"
										class="rounded-lg"></v-text-field>
								</v-col>
								<!-- form button -->
								<v-col
									cols="12"
									md="2">
									<v-btn
										:disabled="
											loadingTests || loadingTestsSubjects
										"
										:loading="
											loadingTests || loadingTestsSubjects
										"
										color="primary"
										@click="filteredTests">
										بحث
									</v-btn>
								</v-col>
							</v-row>
						</v-form>

						<!-- table -->
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-2">
							<v-data-table
								sort-by="Test.Date"
								:headers="testsHeader"
								:items="testsArr || testsItems"
								:loading="loadingTabs || loadingTests"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 220px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- mark template -->
								<template v-slot:item.testDetails="{ item }">
									{{ item.Test.Details }}
								</template>
								<!-- mark template -->
								<template v-slot:item.testType="{ item }">
									{{ item.Test.TestType }}
								</template>
								<!-- mark template -->
								<template v-slot:item.mark="{ item }">
									<div
										class="font-weight-bold"
										style="font-size: inherit">
										<template v-if="item.IsAbsent">
											لم يقدم
										</template>
										<template v-else>
											<span
												:class="
													item.Mark >=
													item.Test.MinGrade
														? 'myGreen--text'
														: 'red--text'
												">
												{{ item.Mark }}
											</span>
											\
											{{ item.TestMaxMark }}
										</template>
									</div>
								</template>
								<!-- mark template -->
								<template v-slot:item.Test.Date="{ item }">
									{{
										moment(item.Test.Date).format(
											'YYYY/MM/DD',
										)
									}}
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- student behavior -->
					<v-tab-item
						value="behavior"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="behaviorHeader"
								:items="behaviorItems"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showBehaviorExpander"
								:expanded.sync="behaviorExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 180px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- mark template -->
								<template v-slot:item.teacher="{ item }">
									{{
										item.TeacherName || item.SupervisorName
									}}
								</template>
								<!-- mark template -->
								<template v-slot:item.Date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>
								<!-- expand template -->
								<template
									v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											{{ item.Content }}
											<br />
											<span class="text--secondary">
												{{
													moment(item.Date).format(
														'YYYY/MM/DD',
													)
												}}
											</span>
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- student notes -->
					<v-tab-item
						value="notes"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="notesHeader"
								:items="notesItems"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showNotesExpander"
								:expanded.sync="notesExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 180px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- mark template -->
								<template v-slot:item.Date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>
								<!-- expand template -->
								<template
									v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											{{ item.Note }}
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- student attendance -->
					<v-tab-item
						value="attendance"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="attendanceHeader"
								:items="attendanceItems"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showAttendanceExpander"
								:expanded.sync="attendanceExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 180px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- date -->
								<template v-slot:item.type="{ item }">
									{{
										item.HasAttend
											? item.IsLate
												? 'متأخر'
												: 'انصراف مبكر'
											: 'غياب'
									}}
								</template>

								<!-- date -->
								<template v-slot:item.date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>

								<!-- date -->
								<template v-slot:item.Justification="{ item }">
									{{ item.Justification | isAvailable }}
								</template>
								<!-- date -->

								<template
									v-slot:item.ParentJustification="{ item }">
									{{ item.ParentJustification | isAvailable }}
								</template>

								<!-- expanded template -->
								<template
									v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											<span>{{
												item.Justification
											}}</span>
											<span
												v-if="
													$vuetify.breakpoint.mobile
												">
												({{ item.ParentJustification }})
											</span>
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
					</v-tab-item>

					<!-- student finance -->
					<v-tab-item
						value="finance"
						class="pb-1">
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-6">
							<v-data-table
								:headers="financeHeader"
								:items="
									financeItems.AllFunds?.filter(
										(e) => e.InAmount || e.OutAmount,
									)
								"
								:loading="loadingTabs"
								item-key="Id"
								@click:row="showFinanceExpander"
								:expanded.sync="financeExpanded"
								:show-expand="$vuetify.breakpoint.mobile"
								dense
								:items-per-page="-1"
								hide-default-footer
								height="calc(100vh - 320px)"
								fixed-header
								mobile-breakpoint="0">
								<!-- funds template -->
								<template v-slot:item.Date="{ item }">
									{{ moment(item.Date).format('YYYY/MM/DD') }}
								</template>
								<!-- funds template -->
								<template v-slot:item.type="{ item }">
									{{ item.FundType?.Name }}
								</template>
								<!-- receipt template -->
								<template v-slot:item.InAmount="{ item }">
									<span class="font-weight-bold">
										{{
											new Intl.NumberFormat().format(
												item.InAmount,
											) | isAvailable(item.InAmount)
										}}
									</span>
								</template>
								<!-- payment template -->
								<template v-slot:item.OutAmount="{ item }">
									<span class="font-weight-bold">
										{{
											new Intl.NumberFormat().format(
												item.OutAmount,
											) | isAvailable(item.OutAmount)
										}}
									</span>
								</template>
								<!-- expanded template -->
								<template
									v-slot:expanded-item="{ headers, item }">
									<td :colspan="headers.length">
										<div class="px-2 py-4">
											<strong v-if="item.Details">
												{{ item.FundType?.Name }}/{{
													item.Details
												}}
												<br />
											</strong>
											<span v-if="item.BookId">
												رقم الدفتر: {{ item.BookId }}
											</span>
											<span v-if="item.ReceiptId">
												| رقم الوصل:
												{{ item.ReceiptId }}
											</span>
										</div>
									</td>
								</template>
							</v-data-table>
						</v-card>
						<v-card
							class="rounded-lg elevation-1 overflow-hidden mt-4 pa-6">
							<div
								class="d-flex justify-center"
								v-if="loadingTabs">
								<v-progress-circular indeterminate>
								</v-progress-circular>
							</div>
							<v-row v-else>
								<v-col
									cols="12"
									sm="7">
									مستحقات المدرسة:
									<strong>
										{{
											Intl.NumberFormat().format(
												financeItems.TotalInAmountEducation,
											)
										}}
										\
										{{
											Intl.NumberFormat().format(
												financeItems.TotalEducation,
											)
										}}
									</strong>
								</v-col>
								<v-col
									cols="12"
									sm="5">
									الباقي:
									<strong>
										{{
											Intl.NumberFormat().format(
												financeItems.TotalEducation -
													financeItems.TotalInAmountEducation,
											)
										}}
									</strong>
								</v-col>
								<v-col
									cols="12"
									sm="6">
									مستحقات مواصلات:
									<strong>
										{{
											financeItems.TotalInAmountTransports
										}}
										\
										{{ financeItems.TotalTransport }}
									</strong>
								</v-col>
							</v-row>
						</v-card>
					</v-tab-item>

					<!-- student schedule -->
					<v-tab-item
						value="workingHours"
						class="pb-1">
						<div
							class="d-flex align-center justify-center"
							style="height: 50vh"
							v-if="loadingTabs">
							<v-progress-circular indeterminate>
							</v-progress-circular>
						</div>
						<div
							class="schedule-container"
							dir="ltr"
							v-else>
							<div
								class="schedule-container__inner"
								dir="rtl">
								<working-hours :data="schedule"></working-hours>
							</div>
						</div>
					</v-tab-item>

					<!-- student massages -->
					<v-tab-item
						value="massages"
						class="pb-1">
						<div
							v-if="loadingTabs"
							class="d-flex justify-center align-center"
							style="height: 50vh">
							<v-progress-circular indeterminate>
							</v-progress-circular>
						</div>
						<chat
							v-else
							@update="fetchMessages"
							:messages="messages"
							:student="{
								StudentId: $route.params.id,
								StudentUserName:
									studentProfile.UserName ||
									studentProfile.FullName,
								CourseName: studentProfile.CourseName,
							}"></chat>
					</v-tab-item>

					<!-- student subjects -->
					<v-tab-item
						value="subjects"
						class="pb-1">
						<div class="text-body-1 font-weight-bold mt-2">
							معدلات:
							{{ selectedSubject || 'كل المواد' }}
						</div>

						<div
							class="d-flex align-center justify-center"
							style="height: 80vh"
							v-if="loadingTabs">
							<v-progress-circular indeterminate>
							</v-progress-circular>
						</div>

						<template v-else>
							<div
								class="d-flex align-center justify-center"
								style="height: 200px"
								v-if="loadingSubject">
								<v-progress-circular indeterminate>
								</v-progress-circular>
							</div>
							<!-- chart -->
							<average-chart
								v-else
								:data="averages"></average-chart>
							<!-- table -->
							<v-card
								class="rounded-lg elevation-1 overflow-hidden mt-n4">
								<v-data-table
									:headers="subjectsHeader"
									:items="[
										{
											all: true,
											SubjectName: 'الكل',
											SubjectAverage: statics.Average,
											MaxMarkCount: subjects.reduce(
												(a, c) =>
													Number(a) +
													Number(c.MaxMarkCount),
												0,
											),
										},
										...subjects,
									]"
									item-key="SubjectId"
									@click:row="fetchSubjectStatics"
									dense
									:items-per-page="-1"
									hide-default-footer
									height="calc(100vh - 400px)"
									fixed-header
									mobile-breakpoint="0">
									<template
										v-slot:item.SubjectAverage="{ item }">
										{{
											item.SubjectAverage.includes('%')
												? item.SubjectAverage
												: (item.SubjectAverage % 1
														? Number(
																item.SubjectAverage,
														  ).toFixed(1)
														: item.SubjectAverage) +
												  '%'
										}}
									</template>
								</v-data-table>
							</v-card>
						</template>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>

		<!-- search dialog -->
		<v-dialog
			v-model="dialog"
			width="800">
			<v-card class="pa-5">
				<!-- filter form  -->
				<v-row
					class="ma-0"
					align="center"
					dense>
					<v-col
						cols="6"
						sm="4"
						lg="3">
						<v-select
							v-model="selectedSpecialtyId"
							@click:clear="
								selectedSpecialtyId = null;
								selectedCourseId = null;
							"
							:items="specialties"
							item-text="SpecialtyName"
							item-value="SpecialtyId"
							label="الصف"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-select>
					</v-col>
					<v-col
						cols="6"
						sm="4"
						lg="3">
						<v-select
							v-model="selectedCourseId"
							@click:clear="selectedSpecialtyId = null"
							:disabled="!selectedSpecialtyId"
							:items="
								specialties.find(
									(e) =>
										e.SpecialtyId === selectedSpecialtyId,
								)?.Courses
							"
							item-text="CourseName"
							item-value="Id"
							label="الشعبة"
							outlined
							dense
							hide-details
							clearable
							:menu-props="{ offsetY: true }"
							class="rounded-lg"></v-select>
					</v-col>
					<v-col
						cols="12"
						sm="4"
						lg="3">
						<v-text-field
							v-model="searchName"
							label="اسم الطالب"
							outlined
							dense
							hide-details
							append-icon="mdi-magnify"
							class="rounded-lg"></v-text-field>
					</v-col>
					<v-col cols="auto">
						<v-btn
							:disabled="
								loadingStudents ||
								(!selectedCourseId && !searchName)
							"
							:loading="loadingStudents"
							color="primary"
							@click="search">
							بحث
						</v-btn>
					</v-col>
				</v-row>
				<v-card class="rounded-lg elevation-1 overflow-hidden mt-6">
					<v-data-table
						:headers="studentsHeader"
						:items="students"
						:loading="loadingStudents"
						@click:row="changeStudent"
						dense
						:items-per-page="-1"
						hide-default-footer
						height="calc(100vh - 200px)"
						fixed-header
						mobile-breakpoint="0">
						<!-- name template -->
						<template v-slot:item.name="{ item }">
							<span>
								{{
									item.Student?.Account.FirstName ||
									item.Account.FirstName
								}}
								{{
									item.Student?.Account.LastName ||
									item.Account.LastName
								}}
							</span>
						</template>

						<!-- specialty template -->
						<template v-slot:item.specialty="{ item }">
							<span>{{ item.Specialty.SpecialtyName }}</span>
							<span v-if="$vuetify.breakpoint.mobile">
								- {{ item.Courses.CourseName }}</span
							>
						</template>
					</v-data-table>
				</v-card>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import WorkingHours from '@/components/WorkingHours.vue';
import Chat from '@/components/Chat.vue';
import AverageChart from '@/components/AverageChart.vue';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
	name: 'StudentView',

	components: {
		WorkingHours,
		Chat,
		AverageChart,
	},

	data() {
		return {
			tab: this.$route.query.tab || 'profile',
			dialog: false,

			testsArr: null,
			loadingTests: false,
			testSubjectId: null,
			testDetails: null,

			loading: true,
			loadingTabs: true,
			loadingTestsSubjects: false,

			studentProfile: {},

			behaviorExpanded: [],
			notesExpanded: [],
			financeExpanded: [],
			attendanceExpanded: [],

			testsItems: [],
			subjectsItems: [],
			behaviorItems: [],
			financeItems: [],
			notesItems: [],
			attendanceItems: [],
			schedule: [],
			messages: [],

			statics: [],
			subjects: [],
			subjectStatics: [],
			selectedSubject: null,
			loadingSubject: false,

			selectedSpecialtyId: null,
			selectedCourseId: null,
			searchName: null,
			loadingStudents: false,
			students: [],
		};
	},
	computed: {
		averages() {
			return this.selectedSubject
				? this.subjectStatics.MarksChartResults
				: this.statics.StaticsAverage;
		},

		testsHeader() {
			const testsHeader = [
				{
					text: 'المادة',
					value: 'Test.SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'محتوى الاختبار',
					value: 'testDetails',
					sortable: false,
					showInMobile: true,
				},
				{
					text: 'نوع الاختبار',
					value: 'testType',
					sortable: false,
				},
				{
					text: 'العلامة',
					value: 'mark',
					sortable: false,
					showInMobile: true,
				},
				{ text: 'تاريخ', value: 'Test.Date', showInMobile: true },
			];
			if (this.$vuetify.breakpoint.mobile) {
				return testsHeader.filter((e) => e.showInMobile);
			}
			return testsHeader;
		},
		attendanceHeader() {
			const attendanceHeader = [
				{
					text: 'التاريخ',
					value: 'date',
					showInMobile: true,
				},
				{
					text: 'النوع',
					value: 'type',
					showInMobile: true,
				},
				{
					text: 'التبرير',
					value: 'Justification',
					sortable: false,
				},
				{
					text: 'المبرر',
					value: 'ParentJustification',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return attendanceHeader.filter((e) => e.showInMobile);
			}
			return attendanceHeader;
		},
		behaviorHeader() {
			const behaviorHeader = [
				{
					text: 'الاستاذ/الموجه',
					value: 'teacher',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'المادة',
					value: 'SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
				},
				{
					text: 'الملاحظة',
					value: 'Content',
					sortable: false,
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return behaviorHeader.filter((e) => e.showInMobile);
			}
			return behaviorHeader;
		},
		notesHeader() {
			const behaviorHeader = [
				{
					text: 'النوع',
					value: 'NoteType',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{
					text: 'الملاحظة',
					value: 'Note',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return behaviorHeader.filter((e) => e.showInMobile);
			}
			return behaviorHeader;
		},
		financeHeader() {
			const financeHeader = [
				{
					text: 'مقبوض',
					value: 'InAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'عليه',
					value: 'OutAmount',
					showInMobile: true,
					sortable: false,
				},
				{
					text: 'التاريخ',
					value: 'Date',
					showInMobile: true,
				},
				{
					text: 'رقم الدفتر',
					value: 'BookId',
					sortable: false,
				},
				{
					text: 'رقم الوصل',
					value: 'ReceiptId',
					sortable: false,
				},
				{
					text: 'النوع',
					value: 'type',
					sortable: false,
				},
				{
					text: 'تفاصيل',
					value: 'Details',
					sortable: false,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return financeHeader.filter((e) => e.showInMobile);
			}
			return financeHeader;
		},
		subjectsHeader() {
			const subjectsHeader = [
				{
					width: '50%',
					text: 'المادة',
					value: 'SubjectName',
					showInMobile: true,
					sortable: false,
				},
				{
					width: '30%',
					text: 'المعدل',
					value: 'SubjectAverage',
					showInMobile: true,
				},
				{
					width: '20%',
					text: 'عدد العلامات الكاملة',
					value: 'MaxMarkCount',
					showInMobile: true,
				},
			];
			if (this.$vuetify.breakpoint.mobile) {
				return subjectsHeader.filter((e) => e.showInMobile);
			}
			return subjectsHeader;
		},

		studentsHeader() {
			const studentsHeader = [
				{
					text: 'اسم الطالب',
					value: 'name',
					align: 'start',
					showInMobile: true,
				},
				{
					text: 'الصف',
					value: 'specialty',
					align: 'start',
					showInMobile: true,
				},
				{
					text: 'الشعبة',
					value: 'Courses.CourseName',
					align: 'start',
				},
			];
			if (this.$vuetify.breakpoint.mobile)
				return studentsHeader.filter((e) => e.showInMobile);
			return studentsHeader;
		},

		...mapState({
			specialties: (state) => state.specialties.specialties,
		}),
	},

	methods: {
		fetchProfile() {
			this.loading = true;
			this.$store
				.dispatch('student/fetchProfile', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.studentProfile = res;
				})
				.finally(() => {
					this.loading = false;
				});
		},
		fetchTests() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchTests', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.testsItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSubjects() {
			this.loadingTestsSubjects = true;
			this.$store
				.dispatch('courses/fetchSubjects', {
					courseId: this.studentProfile.CourseId,
				})
				.then((res) => {
					this.subjectsItems = res;
				})
				.finally(() => {
					this.loadingTestsSubjects = false;
				});
		},
		fetchNotes() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchNotes', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.behaviorItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchManagerNotes() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchManagerNotes', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.notesItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchAttendance() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchAttendance', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.attendanceItems = [
						...res.AbsenceList,
						...res.EarlyLeaveList,
						...res.LateList,
					].sort((a, b) => {
						return this.moment(a.Date).isAfter(b.Date) ? -1 : 1;
					});
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchFunds() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchFunds', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.financeItems = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchSchedule() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchSchedule', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.schedule = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchMessages() {
			this.loadingTabs = true;
			this.$store
				.dispatch('student/fetchMessages', {
					studentId: this.$route.params.id,
				})
				.then((res) => {
					this.messages = res;
				})
				.finally(() => {
					this.loadingTabs = false;
				});
		},
		fetchStaticsAndSubjects() {
			this.loadingTabs = true;
			Promise.all([
				this.$store
					.dispatch('student/fetchStatics', {
						studentId: this.$route.params.id,
					})
					.then((res) => {
						this.statics = res;
					}),
				this.$store
					.dispatch('student/fetchAllSubjects', {
						studentId: this.$route.params.id,
					})
					.then((res) => {
						this.subjects = res;
					}),
			]).finally(() => {
				this.loadingTabs = false;
			});
		},

		fetchSubjectStatics(item) {
			if (item.all) {
				this.selectedSubject = null;
				this.loadingSubject = true;
				setTimeout(() => {
					this.loadingSubject = false;
				}, 500);
				return;
			}
			this.selectedSubject = item.SubjectName;
			this.loadingSubject = true;
			this.$store
				.dispatch('student/fetchSubjectStatics', {
					studentId: this.$route.params.id,
					subjectId: item.SubjectId,
				})
				.then((res) => {
					this.subjectStatics = res;
				})
				.finally(() => {
					this.loadingSubject = false;
				});
		},

		filteredTests() {
			this.loadingTests = true;
			setTimeout(() => {
				this.loadingTests = false;

				this.testsArr = this.testsItems
					.filter((e) => {
						return this.testSubjectId
							? e.Test.SubjectId === this.testSubjectId
							: true;
					})
					.filter((e) => {
						return this.testDetails
							? e.Test.Details.includes(this.testDetails)
							: true;
					});
			}, 500);
		},

		// for search
		search() {
			if (this.selectedCourseId) this.fetchStudentByCourse();
			else if (this.searchName) this.fetchStudentsByName();
			else {
				this.selectedCourseId = null;
				this.selectedSpecialtyId = null;
				this.searchName = null;
				this.searched = false;
			}
		},
		fetchStudentByCourse() {
			this.loadingStudents = true;
			this.$store
				.dispatch('student/fetchStudentsByCourse', {
					courseId: this.selectedCourseId,
				})
				.then((res) => {
					this.students = res;
					if (this.searchName) {
						this.students = this.students.filter((e) => {
							return (
								e.Student.Account.FirstName.includes(
									this.searchName,
								) ||
								e.Student.Account.LastName.includes(
									this.searchName,
								)
							);
						});
					}
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
		fetchStudentsByName() {
			this.loadingStudents = true;
			this.$store
				.dispatch('student/fetchStudentsByName', {
					studentName: this.searchName,
				})
				.then((res) => {
					this.students = res;
				})
				.finally(() => {
					this.loadingStudents = false;
				});
		},
		fetchSpecialties() {
			this.loadingStudents = true;
			this.$store.dispatch('specialties/fetchAll').finally(() => {
				this.loadingStudents = false;
			});
		},
		changeStudent(item) {
			this.$router.replace({ params: { id: item.Student.Id } });
			this.fetchProfile();
			this.tab = 'profile';
			this.dialog = false;
		},

		// style methods
		showBehaviorExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.behaviorExpanded.findIndex(
					(i) => i === item,
				);
				this.behaviorExpanded.splice(indexExpanded, 1);
			} else {
				this.behaviorExpanded.push(item);
			}
		},
		showNotesExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.notesExpanded.findIndex(
					(i) => i === item,
				);
				this.notesExpanded.splice(indexExpanded, 1);
			} else {
				this.notesExpanded.push(item);
			}
		},
		showFinanceExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.financeExpanded.findIndex(
					(i) => i === item,
				);
				this.financeExpanded.splice(indexExpanded, 1);
			} else {
				this.financeExpanded.push(item);
			}
		},
		showAttendanceExpander(item, event, c) {
			if (!this.$vuetify.breakpoint.mobile) return;
			if (event.isExpanded) {
				const indexExpanded = this.attendanceExpanded.findIndex(
					(i) => i === item,
				);
				this.attendanceExpanded.splice(indexExpanded, 1);
			} else {
				this.attendanceExpanded.push(item);
			}
		},
		moment,
	},

	created() {
		this.fetchProfile();
		this.fetchSpecialties();

		if (this.$route.query.courseId && this.$route.query.specialtyId) {
			this.selectedCourseId = Number(this.$route.query.courseId);
			this.selectedSpecialtyId = Number(this.$route.query.specialtyId);
			this.fetchStudentByCourse();
		}
	},

	metaInfo: {
		title: 'Student',
		titleTemplate: '%s | Edunix',
	},
};
</script>
<style scoped lang="scss"></style>
