import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import i18n from './lang/index';
import VueApexCharts from 'vue-apexcharts';
import VueMeta from 'vue-meta';
import axios from 'axios';
import { BASE_URL } from './helpers/constants';
import './registerServiceWorker';

Vue.component('apexchart', VueApexCharts);
Vue.use(VueMeta);

const $eventBus = new Vue();
Vue.prototype.$eventBus = $eventBus;

Vue.filter('isAvailable', (val, val2 = true) => {
  return (val2 && val) || '-';
});

window.axios = axios.create({
  baseURL: BASE_URL,
});
window.axios.defaults.headers.common['accept-language'] = 'ar';

window.axios.interceptors.response.use(
  function (response) {
    return response;
  },

  function (error) {
    console.log(error);
    if (!window.navigator.onLine) {
      $eventBus.$emit('error', true);
      return Promise.reject(error);
    }
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch('auth/logout');
        $eventBus.$emit(
          'show-snackbar',
          true,
          'تم تعديل معلومات الحساب',
        );
        if (router.history.current.name !== 'login')
          router.replace({ name: 'login' });
      } else {
        $eventBus.$emit('error', false);
      }
    } else if (
      error.code === 'ERR_NETWORK' ||
      error.code === 'ETIMEDOUT' ||
      error.code === 'ECONNABORTED'
    ) {
      $eventBus.$emit('error', true);
    } else {
      $eventBus.$emit('error', false);
    }
    return Promise.reject(error);
  },
);

if ('auth' in localStorage) {
  const user = JSON.parse(localStorage.getItem('auth'));
  if (user) {
    store
      .dispatch('auth/login', {
        userName: user?.userName,
        password: user?.password,
        centerId: user?.centerId,
      })
      .finally(() => {
        $eventBus.$emit('loader', false);
      });
  } else {
    router.replace({ name: 'login' });
  }
} else {
  router.replace({ name: 'login' });
}

let loggedIn = true;
router.beforeEach((to, from, next) => {
  if (from.name) loggedIn = store.state.auth.user;
  // if there is no user loged in route to login page
  if (!loggedIn && to.name !== 'login') {
    next({ name: 'login' });
  } else {
    next();
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
