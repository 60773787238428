<template>
	<v-app-bar
		height="60"
		app
		flat
		hide-on-scroll
		scroll-target="#main"
		:absolute="!$vuetify.breakpoint.mobile"
		color="white"
		:class="{ 'rounded-tr-xl': !$vuetify.breakpoint.mobile }">
		<!-- humberger -->
		<v-app-bar-nav-icon
			@click="$emit('change-navbar')"
			:class="{
				'd-none': !$vuetify.breakpoint.mobile,
			}"></v-app-bar-nav-icon>

		<!-- title -->
		<img
			src="../assets/EdunixLogo.png"
			class="edunix-logo" />
		<h1 class="primary--text text-h6 font-weight-bold">
			{{ titles[$route.name] || 'عنوان' }}
		</h1>

		<v-spacer></v-spacer>

		<!-- tools -->
		<v-btn icon><v-icon class="black--text">mdi-bell</v-icon></v-btn>

		<v-menu offset-y>
			<!-- menu btn -->
			<template v-slot:activator="{ on, attrs }">
				<v-btn
					text
					depressed
					v-bind="attrs"
					v-on="on"
					:icon="$vuetify.breakpoint.mobile"
					><v-icon class="black--text">mdi-account-circle</v-icon>
					<span
						class="ms-3"
						:class="{ 'd-none': $vuetify.breakpoint.mobile }">
						{{ user?.name }}
						<v-icon class="black--text">mdi-chevron-down</v-icon>
					</span></v-btn
				>
			</template>
			<!-- menu body -->
			<v-list>
				<v-list-item>
					<v-list-item-title @click="logout">
						تسجيل الخروج
					</v-list-item-title>
				</v-list-item>
			</v-list>
		</v-menu>

		<v-btn
			icon
			@click="$router.back()">
			<v-icon class="black--text">mdi-arrow-left</v-icon>
		</v-btn>
	</v-app-bar>
</template>

<script>
import { mapState } from 'vuex';

export default {
	name: 'AppBar',
	data() {
		return {
			titles: {
				home: 'الرئيسية',
				allTeachers: 'المدرسين',
				allStudents: 'الطلاب',
				allCourses: 'الشعب',
				teacher: 'ملف المدرس',
				student: 'ملف الطالب',
				course: 'ملف الشعبة',
				grades: 'العلامات',
			},
		};
	},

	computed: {
		...mapState({
			user: (state) => state.auth.user,
		}),
	},

	methods: {
		logout() {
			this.$store.dispatch('auth/logout');
			this.$eventBus.$emit('show-snackbar', false, 'تم تسجيل الخروج');
			this.$router.replace({ name: 'login' });
		},
	},
};
</script>

<style>
.edunix-logo {
	width: 60px;
	height: 60px;
	object-fit: contain;
}
@media (max-width: 768px) {
	.edunix-logo {
		width: 40px;
		height: 40px;
	}
}
</style>
