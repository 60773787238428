import * as types from '../mutations';

export default {
    namespaced: true,

    state: {
        icon: null,
        i: null,
    },

    actions: {
        fetchIcon({ rootState, commit }) {
            return window.axios
                .get(rootState.auth.url + '/get-logo', {
                    responseType: 'blob',
                })
                .then((res) => {
                    // console.log(res.data);
                    // commit("asd", res.data)
                    var objectURL = URL.createObjectURL(res.data);
                    // console.log(objectURL);
                    commit(types.ICON, objectURL);
                    // return res.data
                });
        },
    },

    mutations: {
        [types.ICON](state, icon) {
            state.icon = icon;
        },
        asd(state, i) {
            state.i = i;
        },
    },
};
